import React from 'react';
import { TokenAmount } from '@dusalabs/sdk';
import { Link } from 'react-router-dom';
import PoolName from 'components/PoolName';
import { useTokenValues } from 'hooks/useFetchData/tokenValue';
import { routeNames } from 'routes';
import { FEATURE_FLAGS } from 'utils/config';
import { getMasIfWmas, getWmasIfMas } from 'utils/coreMethods';
import { printUSD, toFraction } from 'utils/methods';
import { tokens } from 'utils/tokens';
import { PoolLiquidity } from 'utils/types';
import './Pools.scss';

const g = (x: PoolLiquidity, dollarValues: number[], isFees = false) => {
  const token0Index = tokens.findIndex((t) => t.equals(x.token0));
  const token1Index = tokens.findIndex((t) => t.equals(x.token1));
  if (token0Index === -1 || token1Index === -1) return toFraction(0);

  const token0Value = dollarValues[token0Index];
  const token1Value = dollarValues[token1Index];
  const share0 = toFraction(token0Value).multiply(
    new TokenAmount(x.token0, isFees ? x.fees0 : x.amount0)
  );
  const share1 = toFraction(token1Value).multiply(
    new TokenAmount(x.token1, isFees ? x.fees1 : x.amount1)
  );
  return share0.add(share1);
};

const PoolOwnItem = (props: { pool: PoolLiquidity; isAutonomous: boolean }) => {
  const { token0, token1, binStep } = props.pool;
  const tokenA = getMasIfWmas(token0);
  const tokenB = getMasIfWmas(token1);

  const dollarValues = useTokenValues();
  const poolValue = g(props.pool, dollarValues);
  const feesValue = g(props.pool, dollarValues, true);

  return (
    <React.Fragment key={token0.symbol + binStep}>
      <Link
        to={routeNames.liquidityItem
          .replace(':token0', getWmasIfMas(token0).address)
          .replace(':token1', getWmasIfMas(token1).address)
          .replace(':binStep', binStep.toString())}
        className='asset-item'
      >
        <PoolName token0={tokenA} token1={tokenB} binStep={binStep} />
        <div className='asset-details'>
          <div>
            <div>Balance</div>
            <div className='asset-token-amount'>
              {/* <span>
                {tokenA.symbol}{' '}
                {formatNumberWithSubscriptZeros(
                  printBigintIsh(tokenA, amount0)
                )}
              </span>
              <span>-</span>
              <span>
                {tokenB.symbol}{' '}
                {formatNumberWithSubscriptZeros(
                  printBigintIsh(tokenB, amount1)
                )}
              </span> */}
              <span>${printUSD(Number(poolValue.toFixed(1)))} </span>
            </div>
          </div>
          <div>
            <div>Fees</div>
            <div className='asset-token-amount'>
              {props.isAutonomous ? (
                <span className='info-fees-al'>
                  Fees in autonomous liquidity are automatically claimed
                </span>
              ) : (
                <>
                  {/* <span>
                    {tokenA.symbol}{' '}
                    {formatNumberWithSubscriptZeros(
                      printBigintIsh(tokenA, fees0)
                    )}
                  </span>
                  <span>-</span>
                  <span>
                    {tokenB.symbol}{' '}
                    {formatNumberWithSubscriptZeros(
                      printBigintIsh(tokenB, fees1)
                    )}
                  </span> */}
                  <span>${printUSD(Number(feesValue.toFixed(2)))}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};

export const PoolsOwn = ({
  poolsOwn,
  isAutonomous
}: {
  poolsOwn: PoolLiquidity[];
  isAutonomous: boolean;
}) => {
  const dollarValues = useTokenValues();
  const sortedPools = poolsOwn.toSorted((a, b) => {
    const poolAValue = g(a, dollarValues);
    const poolBValue = g(b, dollarValues);

    return poolBValue.lessThan(poolAValue) ? -1 : 1;
  });

  return (
    <div className='PoolsOwn'>
      {FEATURE_FLAGS.AUTONOMOUS_LIQUIDITY && (
        <div className='autonomous-manual-div'>
          {isAutonomous ? 'Autonomous' : 'Manual'} Liquidity
        </div>
      )}
      <div className='assets-owned-container'>
        {sortedPools.map((pool, index) => (
          <React.Fragment key={pool.binStep + '' + index}>
            <PoolOwnItem pool={pool} isAutonomous={isAutonomous} />
            {index < poolsOwn.length - 1 && <div className='line'></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PoolsOwn;
