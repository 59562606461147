import React, { useContext, useState } from 'react';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'components/Dropdown';
import SwitchButton from 'components/SwitchButton';
import './index.scss';
import { SettingsContext } from 'context/SettingsContext';

const GlobalSettings = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const { interfaceTheme, setInterfaceTheme, isOverlay, setIsOverlay } =
    useContext(SettingsContext);

  const toggleTheme = (style: string) => {
    if (interfaceTheme === style) {
      if (style === 'Dusa') {
        setInterfaceTheme('Halloween');
      } else setInterfaceTheme('Dusa');
      return;
    }
    setInterfaceTheme(style);
  };

  return (
    <Dropdown
      isOpen={showDropDown}
      setIsOpen={setShowDropDown}
      triggerButton={
        <div className='trigger'>
          <FontAwesomeIcon icon={faGear} />
        </div>
      }
      position='bottom left'
    >
      <div className='settings-container'>
        <h3>Interface Settings</h3>
        <div className='settings-content'>
          {[
            { theme: 'Dusa 🪼' },
            { theme: 'Halloween 🎃' },
            { theme: 'Winter ❄️', sub: 'Snowflakes' }
          ].map((item) => {
            const theme = item.theme.split(' ')[0];
            const sub = item.sub;
            return (
              <div className='settings-theme' key={theme}>
                <div className='settings-theme-item'>
                  <span>{item.theme}</span>
                  <SwitchButton
                    isActive={interfaceTheme === theme}
                    onClick={() => toggleTheme(theme)}
                  />
                </div>
                {sub === 'Snowflakes' && interfaceTheme === 'Winter' && (
                  <div className='settings-theme-item'>
                    <span className='tabulation'>{sub}</span>
                    <SwitchButton
                      isActive={isOverlay}
                      onClick={() => setIsOverlay(!isOverlay)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Dropdown>
  );
};

export default GlobalSettings;
