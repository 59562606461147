import { useContext, useState } from 'react';
import { faEllipsis, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx/lite';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logo_halloween from 'assets/img/logo_halloween.png';
import logo_white from 'assets/img/logo_white.png';
import logo_winter from 'assets/img/logo_winter.png';
import Dropdown from 'components/Dropdown';
import GlobalSettings from 'components/GlobalSettings';
import WalletConnect from 'components/WalletConnect';
import { AccountWrapperContext } from 'context/AccountWrapperContext';
import { SettingsContext } from 'context/SettingsContext';
import useResizeWindow from 'hooks/useResizeWindow';
import useScroll from 'hooks/useScroll';
import { routeNames } from 'routes';
import { FEATURE_FLAGS } from 'utils/config';
import './index.scss';

const governors = [
  'AU1256WEQEjShXTQTHxSXLTTyvWDtyxa3gSUvvwRLGiF3SJez9aLA',
  'AU128Z4DFp9CRqC8XWgAc2HuTBsjwhcNRN4oegG5QXDycXYY7VAoA',
  'AU12CeXRAM6fwSyzLGksSx7HdDnFQVGwyAZk8QPVQ7tBENhm3Hs9x',
  'AU12izDEXJ9e5wqMWiQJnzYLr2zBGQN34SGpKLLVChQCFvcnszQMU',
  'AU12jWU88jCx8Pr5gptgM3EUfYuoA5g2jCauFRLZyWzEB7WtByTod',
  'AU12XWww7S55ge37J3iweENt5qAC2vsUx7SK5fnnTpRo22bEV9GRa',
  'AU1cSDyJU2FZNr54mzRAoTrSMfk6PpJ57zfLFqTLpkBY7VSKwwX4',
  'AU1EzwCW42wijCjzyAghUSpCybt9c6tV4ntwm6ZPiJjBxggyhf1E',
  'AU1gxiHJw2EXkgDq73rMF1LwCU1GUPtkS96Df2T5VrqfYnLDn3CP',
  'AU1GyrzedqXPSqjQj5rz5sLPvti968MLB3fSEumik83hw9DNPUFJ',
  'AU1RddqvuejNDH1DxEjQ674fJZuEVUzeEPosUxQUykyMohRk1uie',
  'AU1VnvMGmpBG37oJzZFmqtXq3y5Mfkp91y2dVESXUJjeEzort2pR',
  'AU1GkftgcEZdjv1KRCqBDCWgZfnFPf8ftwHDxACA3MZsU2DbMCWY'
];

const Header = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const { connected, connectedAddress } = useContext(AccountWrapperContext);
  const { interfaceTheme } = useContext(SettingsContext);
  const path = useLocation().pathname;

  const displayGovernance =
    FEATURE_FLAGS.GOVERNANCE && governors.includes(connectedAddress);

  const scrollPosition = useScroll();
  const { innerWidth: windowWidth } = useResizeWindow();
  const navItems = [
    { title: 'Trade', link: '/trade' },
    { title: 'Pools', link: '/pools' },
    { title: 'Portfolio', link: '/portfolio' }
  ];

  FEATURE_FLAGS.DCA && navItems.splice(2, 0, { title: 'DCA', link: '/dca' });
  FEATURE_FLAGS.POINTS && navItems.push({ title: 'Points', link: '/points' });

  const breakpoint = 950;
  const smallScreen = windowWidth <= breakpoint;
  const largeScreen = windowWidth > breakpoint;

  const bridgeItem = { title: 'Bridge', link: 'https://bridge.massa.net/' };
  const cedeItem = { title: 'CEX on-ramp', link: '/cexOnRamp' };

  largeScreen && navItems.push(bridgeItem);
  largeScreen && FEATURE_FLAGS.CEDESTORE && navItems.push(cedeItem);

  const otherItems = [{ title: 'Docs', link: 'https://docs.dusa.io' }];

  displayGovernance &&
    otherItems.push({ title: 'Governance', link: '/governance' });
  smallScreen && otherItems.push(bridgeItem);
  smallScreen && FEATURE_FLAGS.CEDESTORE && otherItems.push(cedeItem);

  return (
    <header className={clsx('Header', scrollPosition > 5 && 'scroll')}>
      <div className='link-pages'>
        <Link to={routeNames.home} className='logo-link'>
          <img
            src={
              interfaceTheme === 'Halloween'
                ? logo_halloween
                : interfaceTheme === 'Winter'
                  ? logo_winter
                  : logo_white
            }
            alt='logo'
            height={40}
          />
        </Link>
        <nav>
          {navItems.map((route, index: number) => {
            if (route.link.startsWith('/'))
              return (
                <NavLink
                  key={index}
                  to={route.link}
                  className={clsx(
                    'link-page',
                    index === 0 && path === '/' && 'active'
                  )}
                >
                  {route.title}
                </NavLink>
              );
            return (
              <a
                key={index}
                href={route.link}
                target='_blank'
                rel='noopener noreferrer'
                className='link-page'
              >
                {route.title} <FontAwesomeIcon icon={faExternalLink} />
              </a>
            );
          })}
          <Dropdown
            isOpen={showDropDown}
            setIsOpen={setShowDropDown}
            triggerButton={
              <div className='trigger'>
                <FontAwesomeIcon icon={faEllipsis} />
              </div>
            }
            position={windowWidth > 800 ? 'bottom left' : 'top left'}
          >
            <div className='links-container'>
              {otherItems.reverse().map((route) => {
                if (route.link.startsWith('/'))
                  return (
                    <Link
                      key={route.title}
                      to={route.link}
                      className='dropdown-link'
                      onClick={() => setShowDropDown(false)}
                    >
                      {route.title}
                    </Link>
                  );
                return (
                  <a
                    key={route.title}
                    href={route.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => setShowDropDown(false)}
                    className='dropdown-link'
                  >
                    {route.title} <FontAwesomeIcon icon={faExternalLink} />
                  </a>
                );
              })}
            </div>
          </Dropdown>
        </nav>
      </div>

      <div className='utils'>
        {FEATURE_FLAGS.FAUCET && connected && (
          <Link to={routeNames.faucet} className='faucet'>
            {innerWidth > 640 && 'Faucet '}👛
          </Link>
        )}
        <GlobalSettings />
        <WalletConnect />
      </div>
    </header>
  );
};

export default Header;
