import { InterfaceLevel } from 'context/SettingsContext';
import { storageThemeVersion, routerSC } from './config';
import { MCAP } from './constants';
import { Pool } from './pools';
import { Token, Transaction } from './types';

const betaId = routerSC;

// SLIPPAGE

export const setSwapSlippage = (slippage: number) =>
  localStorage.setItem(`swapSlippage${betaId}`, slippage.toString());

export const getSwapSlippage = (): number =>
  Math.round(Number(localStorage.getItem(`swapSlippage${betaId}`) ?? '5'));

export const setAmountLiquiditySlippage = (slippage: number) =>
  localStorage.setItem(`amountLiquiditySlippage${betaId}`, slippage.toString());

export const getAmountLiquiditySlippage = (): number =>
  Math.round(
    Number(localStorage.getItem(`amountLiquiditySlippage${betaId}`) ?? '50')
  );

export const setPriceLiquiditySlippage = (slippage: number) =>
  localStorage.setItem(`priceLiquiditySlippage${betaId}`, slippage.toString());

export const getPriceLiquiditySlippage = (): number =>
  Math.round(
    Number(localStorage.getItem(`priceLiquiditySlippage${betaId}`) ?? '50')
  );

// AIRDROP

export const setAirdropMcap = (mcap: number) =>
  localStorage.setItem(`airdropMcap${betaId}`, mcap.toString());

export const getAirdropMcap = (): number =>
  Math.round(Number(localStorage.getItem(`airdropMcap${betaId}`) ?? MCAP));

// INTERFACE PREFERENCES

const interfaceLevelKey = `interfaceLevel${betaId}`;
export const setInterfaceLevel = (interfaceLevel: InterfaceLevel) =>
  localStorage.setItem(interfaceLevelKey, interfaceLevel);

export const getInterfaceLevel = (): InterfaceLevel =>
  (localStorage.getItem(interfaceLevelKey) ?? 'pro') as InterfaceLevel;

const showInterfaceAgainKey = `showInterfaceAgain${betaId}`;
export const getShowInterfaceAgain = (): boolean =>
  localStorage.getItem(showInterfaceAgainKey) === null;
export const setShowInterfaceAgain = (show: boolean) =>
  localStorage.setItem(showInterfaceAgainKey, show.toString());

export const getInterfaceTheme = (): string | null =>
  localStorage.getItem('theme' + storageThemeVersion);

export const setInterfaceTheme = (theme: string) =>
  localStorage.setItem('theme' + storageThemeVersion, theme);

export const getIsOverlay = (): boolean =>
  (localStorage.getItem('isOverlay' + storageThemeVersion) ?? 'true') ===
  'true';

export const setIsOverlay = (isOverlay: boolean) =>
  localStorage.setItem('isOverlay' + storageThemeVersion, String(isOverlay));

// TOKENS

const importedTokensKey = `importedTokens${betaId}`;
export const getImportedTokens = (): Token[] => {
  const tokens = JSON.parse(
    localStorage.getItem(importedTokensKey) ?? '[]'
  ) as Token[];
  return tokens.map(transformToken);
};
export const updateImportedTokens = (tokens: Token[]) =>
  localStorage.setItem(importedTokensKey, JSON.stringify(tokens));

const favTokensKey = `favTokens${betaId}`;
export const getFavTokens = (): Token[] => {
  const tokens = JSON.parse(
    localStorage.getItem(favTokensKey) ?? '[]'
  ) as Token[];
  return tokens.map(transformToken);
};

export const updateFavTokens = (fav: Token[]) =>
  localStorage.setItem(favTokensKey, JSON.stringify(fav));

// POOLS

const poolsCreatedKey = `poolsCreated${betaId}`;
export const getPoolsCreated = (): Pool[] => {
  const pools = JSON.parse(
    localStorage.getItem(poolsCreatedKey) ?? '[]'
  ) as Pool[];
  return pools.map((pool: Pool) => ({
    token0: transformToken(pool.token0),
    token1: transformToken(pool.token1),
    binStep: pool.binStep
  }));
};
export const updatePoolsCreated = (pools: Pool[]) =>
  localStorage.setItem(poolsCreatedKey, JSON.stringify(pools));

const poolsImportedKey = `poolsImported${betaId}`;
export const getImportedPools = (): Pool[] => {
  const pools = JSON.parse(
    localStorage.getItem(poolsImportedKey) ?? '[]'
  ) as Pool[];
  return pools.map((pool: Pool) => ({
    token0: transformToken(pool.token0),
    token1: transformToken(pool.token1),
    binStep: pool.binStep
  }));
};
export const updateImportedPools = (pools: Pool[]) =>
  localStorage.setItem(poolsImportedKey, JSON.stringify(pools));

// OTHER

export const getIsDegenMode = (): boolean =>
  localStorage.getItem('isDegenMode') === 'true';

export const setIsDegenMode = (isDegenMode: boolean) =>
  localStorage.setItem('isDegenMode', String(isDegenMode));

export const getRecentTransactions = (address: string): Transaction[] => {
  const key = `recentTransactions${address}${betaId}`;
  const txs = JSON.parse(localStorage.getItem(key) ?? '[]') as Transaction[];
  return txs.sort((a, b) => b.timestamp - a.timestamp);
};

export const addToRecentTransactions = (tx: Transaction, address: string) => {
  const recentTransactions = getRecentTransactions(address);
  recentTransactions.push(tx);

  localStorage.setItem(
    `recentTransactions${address}${betaId}`,
    JSON.stringify(recentTransactions)
  );

  // window.dispatchEvent(new Event('localStorage'));
};

const lastTokensKey = `tokens${betaId}`;
export const getLastTokens = (): [Token | undefined, Token | undefined] => {
  const tokens = JSON.parse(localStorage.getItem(lastTokensKey) ?? '[]');
  return tokens.map(
    (token: Token | undefined) => token && transformToken(token)
  );
};

export const updateLastTokens = (
  tokens: [Token | undefined, Token | undefined]
) => localStorage.setItem(lastTokensKey, JSON.stringify(tokens));

// MISC

// for localStorage serialization issues
export const transformToken = (token: Token) =>
  new Token(
    token.chainId,
    token.address,
    token.decimals,
    token.logoURI,
    token.symbol,
    token.name
  );
